import React, { useState, useEffect } from "react";
import "../assets/css/Banner.css";
import "../assets/css/Header.css";
import "../assets/css/Download.css";
import logo from "../assets/images/Neoin Logo.svg";
// import homeBanner from "../assets/images/banner/banner-9-bg.jpg"
// import bannerImage from "../assets/images/banner/banner-9.png";
import bannershape9 from "../assets/css/img/banner-shape-9.png";
// import TopShape from "../assets/images/bg/experience-bg.png"
// import featureImage1 from "../assets/images/feature/Consent.svg";
// import featureImage2 from "../assets/images/feature/Connect.svg";
// import featureImage3 from "../assets/images/feature/Communicate.svg";
// import featureImage4 from "../assets/images/feature/Engage.svg";
// import left from "../assets/images/feature/back.svg";
// import right from "../assets/images/feature/next.svg";
import exSectionImage from "../assets/images/feature/experience.png";
import section2image from "../assets/images/feature/digital_solutions_img.png";
import InnovateImg from "../assets/images/feature/innovate_img.png";

import neoinIDIcon from "../assets/images/recharge/NEOIN.iD.svg";
import MfaIcon from "../assets/images/recharge/MFA.svg";
import AiScanIcon from "../assets/images/recharge/AI-Enabled Scan.svg";
import DigiSignIcon from "../assets/images/recharge/Digital Sign.svg";

import recharge1 from "../assets/images/recharge/SME.svg";
import recharge2 from "../assets/images/recharge/Genz.svg";
import recharge3 from "../assets/images/recharge/Student.svg";
import recharge4 from "../assets/images/recharge/Corporate.svg";
import recharge5 from "../assets/images/recharge/Alumni Association.svg";
import recharge6 from "../assets/images/recharge/Clubs & Society.svg";
import recharge7 from "../assets/images/recharge/Events.svg";
import recharge8 from "../assets/images/recharge/Banking.svg";
import client03 from "../assets/images/dummies/D3.png";
import client02 from "../assets/images/dummies/D2.png";
import client01 from "../assets/images/dummies/D1.png";
import client04 from "../assets/images/dummies/D4.png";
import client05 from "../assets/images/dummies/D5.png";
import calculatebg from "../assets/images/bg/calculate-bg.png";
// import linebg from "../assets/images/bg/line-bg.png";

import QRcode from "../assets/images/feature/QR Code.png";
// import shotone from "../assets/images/screenshot/Group 13.png";
// import shottwo from "../assets/images/screenshot/Group 2.png";
// import shotthree from "../assets/images/screenshot/Group 5.png";
// import shotfour from "../assets/images/screenshot/Group 6.png";
// import shotfive from "../assets/images/screenshot/Group 8.png";
// import arrow from "../assets/images/screenshot/arrow.png";
// import dev from "../assets/images/screenshot/MAIN.png";
// import { Link } from "react-router-dom";
import Counter from "./Counter";
import Download from "./Download";
import Lightbox from "./LightBox";
import Carousel from "./Carousel";
import HomeCarousel from "./HomeCarousel";


function Index9new() {
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxTitle, setlightboxTitle] = useState("");
  const [activeComponent, setActiveComponent] = useState(false);

  const openLightbox = (htmlOrElement) => {
    setIsOpen(true);
    // setContent(htmlOrElement);
    setlightboxTitle(htmlOrElement);
    setActiveComponent(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [isRightClicked, setIsRightClicked] = useState(false);
  // const handleRightClick = () => {
  //   setIsRightClicked(true);
  //   const leftIcon = document.querySelector(".carousel-control.left img");
  //   leftIcon.style.display = "block"; // Show the left arrow when right arrow is clicked
  //   leftIcon.style.filter = "brightness(150%)"; // Increase the brightness when right is clicked
  // };

  // const handleLeftClick = () => {
  //   setIsRightClicked(false);
  //   const leftIcon = document.querySelector(".carousel-control.left img");
  //   leftIcon.style.filter = "brightness(150%)"; // Set to original brightness when left is clicked
  // };

  return (
    <>
      {/* <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div> */}
      <a href="#0" className="scrollToTop">
        <i className="fas fa-angle-up"></i>
      </a>
      <div className="overlay"></div>
      <section className="banner-9 bg_img_banner oh pos-rel">
        <div className="container">
          <div className="banner-content-9 cl-white">
            <h1 className="title">
              Digital Identity & Data Engine Empowering Ecosystem{" "}
            </h1>
            <p className="mb-2">
              {" "}
              <strong>"Zero Phishing & Zero Deepfaking"</strong>{" "}
            </p>
            <p>
            Customer Identity Verification Starts with Consent, Control of Data & Security
            </p>

           <div className="banner-button-group">
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/welcome`} className="button-4" id="banner-btn-flex">
                CREATE iD
              </a>
            </div>
          </div> 
          {/* <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="pos-rel banner-9-video">
                                <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button popup">
                                    <i className="flaticon-play"></i>
                                </a>
                                <img src={bannerImage} alt="banner" />
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="banner-shape-9">
          <img src={bannershape9} alt="css" />
        </div>
      </section>

      <section className="advance-feature-section padding-top-2 padding-bottom-2 oh">
        <div className="container">
          <div className="advance-feature-item style-two  who-we-are">
            <div className="advance-feature-thumb">
              <img src={section2image} alt="feature" />
            </div>
            <div className="advance-feature-content">
              <div className="section-header left-style mb-olpo">
                <h5 className="cate">Efficiency at your fingertips.</h5>
                <h2 className="title ">Who we are</h2>
                <p className="text-left">
                  <span className="first-word">NEOIN,</span> a digital iD technology with a verified data engine, enhances customer trust by providing <i><a href="#0" className="d-inline">Digital Identity, Identity Management, Access Control, Data Security, and Privacy.</a></i> We offer personalized <i><a href="#0">Data Analytics and Insights, </a></i> which provide reassurance about the authenticity and security of online engagements across the digital ecosystem by implementing robust authentication mechanisms that can protect sensitive data and mitigate the risk of identity theft or fraud.
                </p>
              </div>
              <a href="/about" className="get-button">
                KNOW MORE
              </a>
              {/* <a href="#0" className="get-button">KNOW MORE<i className="flaticon-right"></i></a> */}
            </div>
          </div>
        </div>
      </section>

      <div className="feature-section cce-carousel mob-pt-40">
        <div className="container">
           {/* <div className="owl-theme owl-carousel padding-bottom-2 feature-item-2-slider mb--50">
            <div className="feature-item-2">
              <div className="feature-thumb">
                <img src={featureImage1} alt="feature" />
              </div>
              <div className="feature-content">
                <h4 className="title">Consent</h4>
                <p id="feature-content-desc" className="content-justify">
                  NEOIN consent through Digital ID refers to the process of
                  obtaining permission or authorization from individuals or
                  entities using their digital identities.{" "}
                </p>
              </div>
            </div>
            <div className="feature-item-2">
              <div className="feature-thumb">
                <img src={featureImage2} alt="feature" />
              </div>
              <div className="feature-content">
                <h4 className="title"> Connect </h4>
                <p id="feature-content-desc" className="content-justify">
                  NEOIN connect serves as a foundational element for enabling
                  trusted and secure digital interactions, transactions, and
                  collaborations in today's interconnected world.{" "}
                </p>
              </div>
            </div>
            <div className="feature-item-2">
              <div className="feature-thumb">
                <img src={featureImage3} alt="feature" />
              </div>
              <div className="feature-content">
                <h4 className="title"> Communicate </h4>
                <p id="feature-content-desc" className="content-justify">
                  NEOIN communication ensures secure transmission and exchange
                  of identity data, facilitating authentication, authorization,
                  and access control in digital interactions.
                </p>
              </div>
            </div>

            <div className="feature-item-2">
              <div className="feature-thumb">
                <img src={featureImage4} alt="feature" />
              </div>
              <div className="feature-content">
                <h4 className="title">Engage</h4>
                <p id="feature-content-desc" className="content-justify">
                  NEOIN engage empowers active involvement, participation, or
                  interaction of individuals, organizations, or systems with
                  digital identity solutions or platforms
                </p>
              </div>
            </div>
          </div> */}
        <HomeCarousel />

        </div>
        {/* <div>
          <span className="carousel-control left" onClick={handleLeftClick}>
            <img src={left} alt="Left Icon" />
          </span>
          <span className="carousel-control right" onClick={handleRightClick}>
            <img src={right} alt="Right Icon" />
          </span>
        </div> */}
      </div>

      <section className="experience-section padding-top bg_img_bannerNew top_center bg-max-lg-dark mob-pb-40 oh">
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-xl-10">
                  <div className="section-header left-style">
                    <h5 className="cate">Crafting Digital Solutions</h5>
                    <h2 className="title">
                    Elevating Trust, Preserving Privacy
                    </h2>
                    <p className="text-left">
                      <span className="first-word">NEOIN</span> transforms ecosystems across industries, driving innovation, efficiency, and inclusivity while ensuring security, trust, and compliance with regulatory requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-30-none">
                <div className="col-md-6" id="home-craft-item">
                  <div className="ex-item">
                    <div className="ex-thumb">
                      <img src={neoinIDIcon} alt="feature" />
                    </div>
                    <div className="ex-content">
                      <h4 className="title">NEOIN.iD</h4>
                      <p className="text-center">
                      Digital verified iDs bolster security, mitigate fraud, enhance user experience, and optimize identity verification processes across diverse industries.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="home-craft-item">
                  <div className="ex-item">
                    {/* <div className="ex-item active"> */}
                    <div className="ex-thumb">
                      <img src={MfaIcon} alt="feature" />
                    </div>
                    <div className="ex-content">
                      <h4 className="title">MFA</h4>
                      <p className="text-center">Multi-Factor Authentication significantly enhances security by requiring multiple forms of verification for system or account access.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="home-craft-item">
                  <div className="ex-item">
                    <div className="ex-thumb">
                      <img src={AiScanIcon} alt="feature" />
                    </div>
                    <div className="ex-content">
                      <h4 className="title">AI-Enabled Scan</h4>
                      <p className="text-center">AI-enabled scans through verified data engines offer enhanced accuracy, efficiency, scalability, and decision support capabilities, leveraging AI for business success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="home-craft-item">
                  <div className="ex-item">
                    <div className="ex-thumb">
                      <img src={DigiSignIcon} alt="feature" />
                    </div>
                    <div className="ex-content">
                      <h4 className="title">Digital Signature</h4>
                      <p className="text-center">
                      Digital signatures through verified digital iDs and data engines offer a secure and trustworthy way to ensure both Authenticator and their documents.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 d-none d-xl-block mt-xl-5">
              <img src={exSectionImage} alt="feature" />
            </div>
          </div>
        </div>
      </section>

      <section className="addon-section padding-top oh" id="addon">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header mw-100">
                {/* <h5 className="cate">Single NEOIN ID Facilitate</h5> */}
                <h3 className="title">
                  Connect
                  <span className="dot"></span>
                  Communicate
                  <span className="dot"></span>
                  Engage
                </h3>

                <p className="text-center">
                Verified iDs enable secure connections, communication, and engagement online, enhancing security, trust, and accountability in both public and private usage scenarios, resulting in safer and more reliable online interactions and transactions.
                </p>
              </div>
            </div>
          </div>
          <div className="feature-section">
            <div className="container">
              <div className="circles-wrapper">
                <div className="circle" id="left-circle">
                  <div className="item">
                    <p style={{ "fontSize": "16px" }}>
                      <strong>Public iD</strong>
                    </p>
                  </div>
                </div>
                <div className="line"></div>
                <div className="circle" id="right-circle">
                  <div className="item">
                    <p style={{ "fontSize": "16px" }}>
                      <strong>Private iD</strong>
                    </p>
                  </div>
                </div>
                <div className="qr-container">
                  <div className="qrimage">
                    <img src={QRcode} alt="qrcode" className="home-qr-img" />
                  </div>
                </div>
              </div>

              {/* <section className="shot">
                <div className="shot__slider__wrapper wow animate__animated animate__fadeInUp">
                  <div className="shot__slider owl-carousel owl-theme">
                    <div className="shot__item">
                      <img
                        src={shotone}
                        alt="Screenshot"
                        className="device-image active"
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shottwo}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotone}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotthree}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfour}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfive}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotone}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shottwo}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfour}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfive}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                  </div>
                  <div className="slide__button">
                    <a href="javascript:void(0)" className="prev">
                      <img src={arrow} alt="Previous" />
                    </a>
                    <a href="javascript:void(0)" className="next">
                      <img src={arrow} alt="Next" />
                    </a>
                  </div>
                  <img src={dev} alt="Device" className="device" />
                </div>
              </section> */}


              {/* <section className="shot">
                <div className="shot__slider__wrapper wow animate__animated animate__fadeInUp">
                  <div className="shot__slider owl-carousel owl-theme">
                    <div className="shot__item">
                      <img
                        src={shotone}
                        alt="Screenshot"
                        className="device-image active"
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shottwo}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotone}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotthree}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfour}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfive}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotone}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shottwo}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfour}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                    <div className="shot__item">
                      <img
                        src={shotfive}
                        alt="Screenshot"
                        className="device-image "
                      />
                    </div>
                  </div>
                  <div className="slide__button">
                    <a href="javascript:void(0)" className="prev">
                      <img src={arrow} alt="Previous" />
                    </a>
                    <a href="javascript:void(0)" className="next">
                      <img src={arrow} alt="Next" />
                    </a>
                  </div>
                  <img src={dev} alt="Device" className="device" />
                </div>
              </section> */}

              <section className="padding-top-2 padding-bottom-2"><Carousel /></section>

            </div>
          </div>
        </div>
      </section>

      <section className="recharge-section home-platform-section padding-top oh" id="feature">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 rtl d-none d-xl-block innovate-img-wrapper">
              <img src={InnovateImg} alt="recharge" />
            </div>
            <div className="col-xl-8">
              <div className="section-header left-style">
                <h5 className="cate">Innovate. Elevate. Transform.</h5>
                <h2 className="title">Platform</h2>
                <p className="text-left">
                  <span className="first-word">NEOIN</span> orchestrates highly sophisticated customer journeys across segments at scale to deliver meaningful personalization, connectivity, communication, engagement, campaigns, services, and products on any channel.
                </p>
              </div>
              <div className="recharge-wrapper">
                <a href="/sme" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge1} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">SME</h6>
                  </div>
                </a>
                <a href="/genz" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge2} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">GenZ</h6>
                  </div>
                </a>
                <a href="/bank" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge8} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">Banks & NBFC</h6>
                  </div>
                </a>
                <a href="/associations" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge5} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">Alumni Association</h6>
                  </div>
                </a>
                <a href="/professionals" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge4} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">Professionals</h6>
                  </div>
                </a>
                <a href="/students" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge3} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">Students</h6>
                  </div>
                </a>
                <a href="/individuals" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge6} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">Individuals</h6>
                  </div>
                </a>

                <a href="/events" className="recharge-item">
                  <div className="recharge-thumb">
                    <span className="anime"></span>
                    <div className="thumb">
                      <img src={recharge7} alt="recharge" />
                    </div>
                  </div>
                  <div className="recharge-content">
                    <h6 className="title">Events</h6>
                  </div>
                </a>

                {/* <div className="recharge-item">
                                    <div className="recharge-thumb">
                                        <span className="anime"></span>
                                        <div className="thumb"><img src={recharge8} alt="recharge" /></div>
                                    </div>
                                    <div className="recharge-content">
                                        <h5 className="title">Banking</h5>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="addon-section home-features-section padding-top oh bg_img_bannerNew mob-pb-40 tab-pb-100" id="addon">
        {/* Changes for the Top Shape Image mani*/}
        <div className="container pt-3">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header mw-100">
                {/* <h5 className="cate">Innovate. Elevate. Transform.</h5> */}
                <h2 className="title">Features</h2>
                <p>
                Experience seamless connectivity, communication, and engagement with our platform's robust features, including privacy and security measures.
                </p>
              </div>
            </div>
          </div>
          {activeComponent && (
            <Lightbox
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              lightboxTitle={lightboxTitle}
              setlightboxTitle={setlightboxTitle}
            />
          )}
          <div className="addon-wrapper features-ring">
            <div className="addon-center">
              <img src={logo} alt="addon" />
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Communicate")}
            >
              <lord-icon
                src="https://cdn.lordicon.com/amjaykqd.json"
                trigger="hover"
                colors="primary:#4be1ec,secondary:#0044c6"
                style={{ width: "85px", height: "85px" }}
              />
              <span className="textbelow-comm-circle">Communicate</span>
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Identity")}
            >
              <lord-icon
                src="https://cdn.lordicon.com/fumyyjbn.json"
                trigger="hover"
                colors="primary:#00e9e7,secondary:#0044c6"
                style={{ width: "85px", height: "85px" }}
              ></lord-icon>
              <span className="textbelow">Identity</span>
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Access")}
            >
              <lord-icon
                src="https://cdn.lordicon.com/vivtfghy.json"
                trigger="hover"
                colors="primary:#00e9e7,secondary:#0044c6"
                style={{ width: "85px", height: "85px" }}
              ></lord-icon>
              <span className="textbelow">Access</span>
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Engage")}
            >
              <lord-icon
                src="https://cdn.lordicon.com/vurwejde.json"
                trigger="hover"
                colors="primary:#00e9e7,secondary:#0044c6"
                style={{ width: "85px", height: "85px" }}
              ></lord-icon>
              <span className="textbelow">Engage</span>
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Connect")}
            >
              <lord-icon
                src="https://cdn.lordicon.com/sbzvueri.json"
                trigger="hover"
                colors="primary:#00e9e7,secondary:#0044c6"
                style={{ width: "85px", height: "85px" }}
              ></lord-icon>
              <span className="textbelow">Connect</span>
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Data")}
            >
              {/* <script src="https://cdn.lordicon.com/lordicon.js"></script> */}
              <lord-icon
                src="https://cdn.lordicon.com/ggzhadex.json"
                trigger="hover"
                colors="primary:#00e9e7,secondary:#0044c6"
                style={{ width: "100px", height: "100px" }}>
              </lord-icon>
              <span className="textbelow">Data</span>
            </div>
            <div
              className="item"
              onClick={() => openLightbox("Dashboard")}
            >
              <lord-icon
                src="https://cdn.lordicon.com/ajfmgpbq.json"
                trigger="hover"
                colors="primary:#00e9e7,secondary:#0044c6"
                style={{"width":"85px", "height":"85px"}}>
              </lord-icon>
              <span className="textbelow">Dashboard</span>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-section up--down--overflow section--bg padding-bottom padding-top tab-pb-50 mob-pb-0">
        <div className="container">
          <div className="row flex-wrap-reverse align-items-center mb-30-none">
            <div className="col-lg-6 aligner">
              <div className="row align-items-center mb-30-none">
                <div className="col-md-6 testi-col-1">
                  <div className="review-item-20">
                    <div className="review-item-header">
                      <div className="thumb">
                        <img src={client03} alt="extra-2/client" />
                      </div>
                      <div className="content">
                        <h6 className="title">Siddesh</h6>
                        <span className="info"></span>
                      </div>
                    </div>
                    <div className="ratings">
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                    </div>
                    <blockquote>
                    "Finally found a platform that prioritizes security while connecting users seamlessly. My experience with this app has been nothing short of exceptional!"
                    </blockquote>
                    <strong>Awesome App!</strong>
                  </div>
                  <div className="review-item-20">
                    {/* <div className="review-item-20 active"> */}
                    <div className="review-item-header">
                      <div className="thumb">
                        <img src={client04} alt="extra-2/client" />
                      </div>
                      <div className="content">
                        <h6 className="title">Emily</h6>
                        <span className="info"></span>
                      </div>
                    </div>
                    <div className="ratings">
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                    </div>
                    <blockquote>
                      “In a digital age filled with uncertainties, this app stands out for its unwavering commitment to safeguarding my financial interests. Trust and reliability redefined!”
                    </blockquote>
                    <strong>Awesome App!</strong>
                  </div>
                  <div className="review-item-20">
                    <div className="review-item-header">
                      <div className="thumb">
                        <img src={client01} alt="extra-2/client" />
                      </div>
                      <div className="content">
                        <h6 className="title">Kartik</h6>
                        <span className="info"></span>
                      </div>
                    </div>
                    <div className="ratings">
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                    </div>
                    <blockquote>
                      “From secure transactions to effortless networking, this app has become an integral part of my daily routine. It's like having a personal guardian for my digital life.”
                    </blockquote>
                    <strong>Awesome App!</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="review-item-20">
                    <div className="review-item-header">
                      <div className="thumb">
                        <img src={client02} alt="extra-2/client" />
                      </div>
                      <div className="content">
                        <h6 className="title">Raihan</h6>
                        <span className="info"></span>
                      </div>
                    </div>
                    <div className="ratings">
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                    </div>
                    <blockquote>
                      “With a blend of cutting-edge technology and user-friendly interface, this app makes managing my finances and forging connections a breeze. Convenience and security rolled into one.”
                    </blockquote>
                    <strong>Awesome App!</strong>
                  </div>
                  <div className="review-item-20">
                    <div className="review-item-header">
                      <div className="thumb">
                        <img src={client05} alt="extra-2/client" />
                      </div>
                      <div className="content">
                        <h6 className="title">Avery</h6>
                        <span className="info"></span>
                      </div>
                    </div>
                    <div className="ratings">
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                      <span>
                        <i className="fas fa-star"></i>
                      </span>
                    </div>
                    <blockquote>
                      “This app has redefined the landscape, offering a seamless experience without sacrificing safety. Highly recommended for anyone seeking a trustworthy platform.”
                    </blockquote>
                    <strong>Awesome App!</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 aligner">
              <div className="mb-5 mb-lg-0">
                <div className="section-header left-style mb-olpo">
                  <h5 className="cate">Local Solutions, Global Vision</h5>
                  <h2 className="title">Don’t just take our word for it!</h2>
                  <p>
                  Thousands of professionals and businesses are already enjoying the Platform. Hear what some of them are saying!
                  </p>
                </div>
                <a href="/testimonials" className="button-4" id="banner">
                  KNOW MORE
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container counter-container">
          <div className="counter-wrapper-area-2">
            <div className="counter-wrapper-2">
              <div className="counter-item-2">
                <h2 className="title">
                <Counter end={8}>k</Counter>
                </h2>
                <span className="name">Users</span>
              </div>
              <div className="counter-item-2">
                <h2 className="title">
                <Counter end={10}>+</Counter>
                </h2>
                <span className="name">Countries</span>
              </div>
              <div className="counter-item-2">
                <h2 className="title">
                <Counter end={5}>+</Counter>
                </h2>
                <span className="name">Integrations</span>
              </div>
              <div className="counter-item-2">
                <h2 className="title">
                <Counter end={95}>%</Counter>
                </h2>
                <span className="name">Satisfaction</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="padding-top mt-20 mt-max-xl-0">
        <div className="custom-plan bg_img_line oh">
          <div className="container">
            <div className="custom-wrapper">
              <span className="circle"></span>
              <span className="circle two"></span>
              <div className="calculate-bg">
                <img src={calculatebg} alt="bg" />
              </div>
              <div className="custom-area">
                <div className="section-header cl-white">
                  <h5 className="cate">Connect, Communicate & Engage</h5>
                  <h2 className="title">Explore our Products</h2>
                  <p>
                  Want to see our products in action? Take a guided tour through our demo to discover the features.{" "}
                  </p>
                </div>
                <div className="text-center">
                  <a
                    href="/demo"
                    className="custom-button large-button neoin-theme-shadow"
                  >
                    REQUEST DEMO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* app store section */}

      <Download />

      {/* <section className='download-links'>
                <div className="container row links">
                    {isResponsive ? (
                        <div className="flex-container">
                            <div className="get-started">
                                <h1>Get Started Instantly</h1>
                                <br />
                                <p>Download now and embark on a journey of convenience!</p>
                            </div>
                            <div className="col-12 qr-frame">
                                <img src={QRFrame} alt='QR Frame' />
                            </div>
                            <div className="row store-links">
                                <div className='col-md-4 col-sm-6 res-gplay'>
                                    <a href='https://play.google.com/store/games?hl=en_IN&gl=US&pli=1'>
                                        <img src={GPlay} alt='GOOGLE PLAY' />
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-6 res-astore'>
                                    <a href='https://www.apple.com/in/app-store/'>
                                        <img src={AppStore} alt='APP STORE' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-6 qr-frame">
                                <img src={QRFrame} alt='QR Frame' />
                            </div>
                            <div className="col-md-6">
                                <div className="get-started">
                                    <h1>Get Started Instantly</h1>
                                    <br />
                                    <p>Download now and embark on a journey of convenience!</p>
                                </div>
                                <div className="row store-links">
                                    <div className='col-md-4 col-sm-6'>
                                        <a href='https://play.google.com/store/games?hl=en_IN&gl=US&pli=1'>
                                            <img src={GPlay} alt='GOOGLE PLAY' />
                                        </a>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                        <a href='https://www.apple.com/in/app-store/'>
                                            <img src={AppStore} alt='APP STORE' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section> */}

      {/* app store section ends */}
    </>
  );
}

export default Index9new;
