import React from 'react'
// import bannerbg from "../assets/images/banner/banner-10.jpg"
import bannerbg2 from "../assets/css/img/banner-10-dark.png"
import circle from "../assets/images/banner/banner10/circle.png"
import tri1 from "../assets/images/banner/banner10/tri1.png" 
import tri12 from "../assets/images/banner/banner10/tri2.png" 
import tri3 from "../assets/images/banner/banner10/tri3.png" 
import tri4 from "../assets/images/banner/banner10/tri4.png" 
import tri5 from "../assets/images/banner/banner10/tri5.png" 

// import bannerimg from "../assets/images/banner/banner10/banner10.png"
import joiningForces from "../assets/images/individual/Joining Forces.png"

import sq from "../assets/images/banner/banner10/sq.png"

import workshape from "../assets/css/img/work-shape.png"
import clientbg from "../assets/images/bg/client-bg.jpg"

import how4 from "../assets/images/individual/icons/Digital Card.png"
import how5 from "../assets/images/individual/icons/Engagement.png"
import how6 from "../assets/images/individual/icons/Discounts.png"
import how7 from "../assets/images/individual/icons/PPF Score.png"

// import faster from "../assets/images/feature/faster.png"

import verifyProfile from "../assets/images/individual/Verify Your Individual Profile.png"

import advance1 from "../assets/images/individual/Streamline Your Identity.png"
import advance2 from "../assets/images/individual/Smooth Networking.png"
import advance3 from "../assets/images/individual/Discounted Deals.png"
import advance4 from "../assets/images/individual/AI-Enhanced Profile.png"

import Download from './Download'

function Individuals() {
  return (
    <div>
       {/* <!--============= Banner Section Starts Here =============--> */}
    <section className="banner-10 bg_img_bnr_10 pos-rel oh Individualbanner">
        <div className="bottom-shape d-lg-block d-none">
            <img src={bannerbg2} alt="css"/>
        </div>
        <div className="banner-10-trops d-lg-block d-none">
            <div className="trops-1"><img src={circle} alt="banner10"/></div>
            <div className="trops-2"><img src={tri1} alt="banner10"/></div>
            <div className="trops-3"><img src={tri12} alt="banner10"/></div>
            <div className="trops-4"><img src={tri3} alt="banner10"/></div>
            <div className="trops-5"><img src={tri4} alt="banner10"/></div>
            <div className="trops-6"><img src={tri5} alt="banner10"/></div>
            <div className="trops-7"><img src={sq} alt="banner10"/></div>
        </div>
        <div className="container">
            <div className="row align-items-top">
                <div className="col-lg-8">
                    <div className="banner-content-10 cl-white">
                        <h1 className="Newtitle">Joining Forces with Every Individual</h1>
                        <p>
                        The dynamic digital platform for personal development and achievement.
                        </p>
                        <div className="banner-button-group">
                            <a href={`${process.env.REACT_APP_FRONTEND_URL}/welcome`} className="button-4">Create ID</a>
                            {/* <a href="#0" className="button-4 active">Explore Features</a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 d-lg-block d-none">
                    <div className="banner-thumb-10">
                        <img src={joiningForces} alt="banner"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Banner Section Ends Here =============--> */}


    {/* <!--============= Sponsor Section Section Starts Here =============--> */}
    {/* <div className="sponsor-section padding-top pt-lg-0 mt-lg--90">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="spon-cont text-lg-right">
                        <p>Trusted by :</p>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="sponsor-slider owl-theme owl-carousel">
                        <div className="sponsor-thumb">
                            <img src="./assets/images/sponsor/sponsor1.png" alt="sponsor">
                        </div>
                        <div className="sponsor-thumb">
                            <img src="./assets/images/sponsor/sponsor2.png" alt="sponsor">
                        </div>
                        <div className="sponsor-thumb">
                            <img src="./assets/images/sponsor/sponsor3.png" alt="sponsor">
                        </div>
                        <div className="sponsor-thumb">
                            <img src="./assets/images/sponsor/sponsor4.png" alt="sponsor">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!--============= Sponsor Section Section Ends Here =============--> */}


    {/* <!--============= Re-Charge Section Starts Here =============--> */}
    {/* <section className="recharge-section padding-bottom padding-top oh" id="feature">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-xl-3 rtl d-none d-xl-block">
                    <img src="./assets/images/recharge/recharge.png" alt="recharge">
                </div>
                <div className="col-xl-8">
                    <div className="section-header left-style">
                        <h5 className="cate">Apps that will re-charge your business</h5>
                        <h2 className="title">Ready to transform your business?</h2>
                        <p>In the process of making a app, the satisfaction of users is the most important and the focus is on usability and completeness</p>
                    </div>
                    <div className="recharge-wrapper">
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/1.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">Productivity</h5>
                            </div>
                        </div>
                        <div className="recharge-item active">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/2.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">optimization</h5>
                            </div>
                        </div>
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/3.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">safety</h5>
                            </div>
                        </div>
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/4.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">Sustainability</h5>
                            </div>
                        </div>
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/5.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">Compliance</h5>
                            </div>
                        </div>
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/6.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">Integrations</h5>
                            </div>
                        </div>
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/7.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">Expandability</h5>
                            </div>
                        </div>
                        <div className="recharge-item">
                            <div className="recharge-thumb">
                                <span className="anime"></span>
                                <div className="thumb"><img src="./assets/images/recharge/8.png" alt="recharge"></div>
                            </div>
                            <div className="recharge-content">
                                <h5 className="title">Multilingual</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Re-Charge Section Ends Here =============--> */}


    {/* <!--============= Faster Section Starts Here =============--> */}
    <div className="faster-section padding-top padding-bottom-2 oh">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6">
                    <div className="faster-content">
                        <div className="section-header left-style mb-olpo">
                            <h5 className="cate">Individuals Journey on verified Identity</h5>
                            <h2 className="title">Verify Your Individual Profile</h2>
                            <p>Individuals can enjoy seamless features upon verifying their identity, including a <span className='first-word'>digital business card, communication </span>and <span className='first-word'>engagement tools, discounts and deals,</span> and  <span className='first-word'>AI recommendations</span> for maintaining their PPF score.</p>
                        </div>
                        <div className="group">
                            {/* <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                            <a href="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-5 d-none d-lg-block">
                    <img src={verifyProfile} alt="Verify your Individual Profile" />
                </div>
            </div>
        </div>
    </div>
    {/* <!--============= Faster Section Ends Here =============--> */}


    {/* <!--============= How Section Starts Here =============--> */}
    <section className="how-section padding-bottom padding-top pos-rel pb-xl-0 oh" id="how">
        <div className="bg-xl-100 bg_img" data-background={clientbg}></div>
            <div className="top-shape d-none d-lg-block"><img src={workshape} alt="css"/></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="client-header">
                        <div className="section-header left-style cl-white mb-olpo">
                            <h5 className="cate">Individual's Feature Set for Verified iD</h5>
                            <h2 className="title">Discover Exclusive Benefits Here</h2>
                            <p>Simpler Than You Imagine</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-30-none">
                <div className="col-xl-3 col-sm-6 col-10 col-sm-10 col-md-8">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={how4} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Digital Card</h4>
                            {/* <p>Start using mosto immediately after downloading it from our website just in two clicks.</p>
                            <a href="#0" className="button-3">Get Started <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 col-10 col-sm-10 col-md-8">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={how6} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Engagement</h4>
                            {/* <p>Our app easily integrates with a majority of business software and applications.</p>
                            <a href="#0" className="button-3 active">Get Started <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-10 col-sm-10 col-md-8">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={advance3} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Discounts</h4>
                            {/* <p>Mosto supports 
                                messenger protocols 
                                letting you communicate easily.</p>
                            <a href="#0" className="button-3">Get Started <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-10 col-sm-10 col-md-8">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={how5} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">PPF Score</h4>
                            {/* <p>Manage your working process and get better results with mosto’s 
                                improved workflow.</p>
                            <a href="#0" className="button-3">Get Started <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= How Section Ends Here =============--> */}


    


    {/* <!--============= Pricing Section Starts Here =============--> */}
    {/* <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img pos-rel" data-background="./assets/images/bg/pricing-bg.jpg" id="pricing">
        <div className="top-shape d-none d-md-block">
            <img src="./assets/css/img/top-shape.png" alt="css">
        </div>
        <div className="bottom-shape d-none d-md-block">
            <img src="./assets/css/img/bottom-shape.png" alt="css">
        </div>
        <div className="ball-2" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src="./assets/images/balls/1.png" alt="balls">
        </div>
        <div className="ball-3" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src="./assets/images/balls/2.png" alt="balls">
        </div>
        <div className="ball-4" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src="./assets/images/balls/3.png" alt="balls">
        </div>
        <div className="ball-5" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src="./assets/images/balls/4.png" alt="balls">
        </div>
        <div className="ball-6" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src="./assets/images/balls/5.png" alt="balls">
        </div>
        <div className="ball-7" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src="./assets/images/balls/6.png" alt="balls">
        </div>
        <div className="container">
            <div className="section-header pb-30 cl-white">
                <h5 className="cate">Choose a plan that's right for you</h5>
                <h2 className="title">Simple Pricing Plans</h2>
                <p>
                    Mosto has plans, from free to paid, that scale with your needs. Subscribe to a plan that fits the size of your business.
                </p>
            </div>
            <div className="row justify-content-center mb-30-none">
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">basic</span>
                        <div className="pricing-thumb">
                            <img src="./assets/images/pricing/pricing5.png" alt="pricing">
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>30.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">standard</span>
                        <div className="pricing-thumb">
                            <img src="./assets/images/pricing/pricing6.png" alt="pricing">
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>50.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block active">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">premium</span>
                        <div className="pricing-thumb">
                            <img src="./assets/images/pricing/pricing7.png" alt="pricing">
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>90.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Pricing Section Ends Here =============--> */}

    
    {/* <!--============= Safe Section Starts Here =============--> */}
    {/* <div className="safe-section oh padding-top padding-bottom">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-xl-5 d-none d-lg-block rtl">
                    <img src="./assets/images/feature/safe.png" alt="feature">
                </div>
                <div className="col-lg-6">
                    <div className="safe-content">
                        <div className="section-header left-style mb-olpo">
                            <h5 className="cate">14 Days Money Back Guarantee!</h5>
                            <h2 className="title">100% Risk Free</h2>
                            <p>Don't worry! You are fully protected by our 100% No-Risk Money Back Guarantee. If you aren’t fully satisfied mosto app  over the next 14 days, simply cancel your order, and we won’t bill you. No questions asked.</p>
                        </div>
                        <div className="group">
                            <a href="#0" className="get-button active">Get Started<i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!--============= Safe Section Ends Here =============--> */}


    {/* <!--============= Testimonial Section Starts Here =============--> */}
    {/* <section className="testimonial-feature bg_img pb-xl-0 padding-bottom" data-background="./assets/images/bg/client-bg.jpg">
        <div className="oh pos-rel padding-top">
            <div className="top-shape d-none d-lg-block">
                <img src="./assets/css/img/client-shape.png" alt="css">
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="client-header">
                            <div className="section-header left-style cl-white mb-olpo">
                                <h5 className="cate">Testimonials</h5>
                                <h2 className="title">Over 10.000 happy clients</h2>
                                <p>Everyday reviews from users around the world are an important driver of our team.</p>
                            </div>
                            <a href="#0" className="button-client">Get Free Trial <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="client-slider owl-carousel owl-theme">
                            <div className="client-item">
                                <div className="client-content">
                                    <p>
                                        Perfect work to start on, support is awesome
                                    </p>
                                    <div className="rating">
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star-half-alt"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="client-thumb">
                                    <a href="#0">
                                        <img src="./assets/images/client/client01.png" alt="client">
                                    </a>
                                </div>
                            </div>
                            <div className="client-item">
                                <div className="client-content">
                                    <p>
                                        Perfect work to start on, support is awesome
                                    </p>
                                    <div className="rating">
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star-half-alt"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="client-thumb">
                                    <a href="#0">
                                        <img src="./assets/images/client/client02.png" alt="client">
                                    </a>
                                </div>
                            </div>
                            <div className="client-item">
                                <div className="client-content">
                                    <p>
                                        Perfect work to start on, support is awesome
                                    </p>
                                    <div className="rating">
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                        <span>
                                            <i className="fas fa-star-half-alt"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="client-thumb">
                                    <a href="#0">
                                        <img src="./assets/images/client/client03.png" alt="client">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="counter-wrapper-area-2 mt-xl-0">
                <div className="counter-wrapper-2">
                    <div className="counter-item-2">
                        <h2 className="title"><span className="intitle counter">45</span><span className="intitle">k</span></h2>
                        <span className="name">Users</span>
                    </div>
                    <div className="counter-item-2">
                        <h2 className="title"><span className="intitle counter">90</span><span className="intitle">+</span></h2>
                        <span className="name">Countries</span>
                    </div>
                    <div className="counter-item-2">
                        <h2 className="title"><span className="intitle counter">10</span><span className="intitle">+</span></h2>
                        <span className="name">Integrations</span>
                    </div>
                    <div className="counter-item-2">
                        <h2 className="title"><span className="intitle counter">95</span><span className="intitle">%</span></h2>
                        <span className="name">Satisfaction</span>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Testimonial Section Ends Here =============--> */}


    {/* <!--============= Cost Section Starts Here =============--> */}
    {/* <section className="cost-section padding-bottom padding-top mt-120 mt-max-xl-0">
        <div className="container">
            <div className="cost-wrapper text-center">
                <div className="cost-icon">
                    <div className="icon">
                        <img src="./assets/images/cost/cost.png" alt="cost">
                    </div>
                </div>
                <div className="section-header mb-olpo">
                    <h5 className="cate">Need Something Different</h5>
                    <h3 className="title">We can build a special plan just to fit your needs</h3>
                    <p>We’ve created this handy plan cost calculator just for you. Find out how much your custom plan will cost in under a minute!</p>
                </div>
                <a href="#0" className="button-3">Use Cost Calculator </a>
            </div>
        </div>
    </section> */}
    {/* <!--============= Cost Section Ends Here =============--> */}

    {/* <!--============= Advance Feature Section Starts Here =============--> */}
    <section className="advance-feature-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb IndividualsImg">
                    <img src={advance1} alt="feature" className='img-w-auto'/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Digitize Your Identity with Ease</h5>
                        <h2 className="title">Streamline Your Identity Digitization</h2>
                        <p>Design and customize your digital business card, then effortlessly share it via <span className='first-word'>email, messaging apps, or social media platforms</span> for seamless networking, and visually appealing representation of individual profile.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            
            <div className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb IndividualsImg text-center">
                    <img src={advance2} alt="feature" className='img-w-auto'/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Linking Individuals</h5>
                        <h2 className="title">Smooth Networking, Personal Achievement</h2>
                        <p>Effortlessly connect with other individuals, enhancing communication for <span className='first-word'>productive collaborations</span>, and engage in <span className='first-word'>expanding your network</span> for personal growth and success.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb IndividualsImg">
                    <img src={advance3} alt="feature" id="indi-discount-img"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Great Discounts for Verified iD</h5>
                        <h2 className="title">Discounted Deals, Trusted Partners</h2>
                        <p>Access exclusive deals and offers from trusted partners, <span className='first-word'>saving on various products and services</span>. Maximise savings while enjoying quality purchases through special discounts.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item padding-top-2">
                <div className="advance-feature-thumb IndividualsImg">
                    <img src={advance4} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">AI-Driven Individual Success</h5>
                        <h2 className="title">AI-Enhanced Profile for Financial Success</h2>
                        <p>Track and improve your PPF Score to gauge <span className='first-word'>creditworthiness</span> and <span className='first-word'>financial health</span> for a secure future. Stay informed and make smart financial decisions to maintain a strong credit profile.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>

        </div>
    </section>
    {/* <!--============= Advance Feature Section Ends Here =============--> */}

    <Download />

    </div>
  )
}

export default Individuals